var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-data"},[_c('div',{staticClass:"description"},[_c('p',{domProps:{"innerHTML":_vm._s(("" + (_vm.$t('login.registeraddress'))))}})]),_c('el-form-item',{attrs:{"prop":"road"}},[_c('el-input',{attrs:{"placeholder":((_vm.$t('login.road')) + " *")},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
        _vm.executeRecaptcha}},model:{value:(_vm.registerFormChild.road),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "road", $$v)},expression:"registerFormChild.road"}})],1),_c('div',{staticClass:"is-half"},[_c('el-form-item',{attrs:{"prop":"houseNumber"}},[_c('el-input',{attrs:{"placeholder":((_vm.$t('login.housenumber')) + " *")},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
          _vm.executeRecaptcha}},model:{value:(_vm.registerFormChild.houseNumber),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "houseNumber", $$v)},expression:"registerFormChild.houseNumber"}})],1),_c('el-form-item',{attrs:{"prop":"staircaseNumber"}},[_c('el-input',{attrs:{"placeholder":("" + (_vm.$t('login.staircasenumber')))},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
          _vm.executeRecaptcha}},model:{value:(_vm.registerFormChild.staircaseNumber),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "staircaseNumber", $$v)},expression:"registerFormChild.staircaseNumber"}})],1)],1),_c('div',{staticClass:"is-half"},[_c('el-form-item',{attrs:{"prop":"floor"}},[_c('el-input',{attrs:{"placeholder":("" + (_vm.$t('login.floor')))},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
          _vm.executeRecaptcha}},model:{value:(_vm.registerFormChild.floor),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "floor", $$v)},expression:"registerFormChild.floor"}})],1),_c('el-form-item',{attrs:{"prop":"door"}},[_c('el-input',{attrs:{"placeholder":("" + (_vm.$t('login.door')))},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
          _vm.executeRecaptcha}},model:{value:(_vm.registerFormChild.door),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "door", $$v)},expression:"registerFormChild.door"}})],1)],1),_c('el-form-item',{attrs:{"prop":"postcode"}},[_c('el-input',{attrs:{"placeholder":((_vm.$t('login.postcode')) + " *")},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
        _vm.executeRecaptcha}},model:{value:(_vm.registerFormChild.postcode),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "postcode", $$v)},expression:"registerFormChild.postcode"}})],1),_c('el-form-item',{attrs:{"prop":"place"}},[_c('el-input',{attrs:{"placeholder":((_vm.$t('login.place')) + " *")},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
        _vm.executeRecaptcha}},model:{value:(_vm.registerFormChild.place),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "place", $$v)},expression:"registerFormChild.place"}})],1),_c('el-form-item',{attrs:{"prop":"country"}},[(_vm.isCurentLangEn)?_c('el-select',{attrs:{"placeholder":((_vm.$t('login.country.select')) + " *")},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
          _vm.executeRecaptcha}},model:{value:(_vm.registerFormChild.country),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "country", $$v)},expression:"registerFormChild.country"}},_vm._l((_vm.country),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name_en,"value":item.code}})}),1):_c('el-select',{attrs:{"placeholder":((_vm.$t('login.country.select')) + " *")},model:{value:(_vm.registerFormChild.country),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "country", $$v)},expression:"registerFormChild.country"}},_vm._l((_vm.country),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name_de,"value":item.code}})}),1)],1),_c('el-form-item',[_c('el-switch',{attrs:{"inactive-text":_vm.$t('login.firma'),"active-color":"#353635","inactive-color":"#A0A0A0"},model:{value:(_vm.companySwitchChild),callback:function ($$v) {_vm.companySwitchChild=$$v},expression:"companySwitchChild"}})],1),(_vm.companySwitchChild)?_c('el-form-item',{attrs:{"prop":"company"}},[_c('el-input',{attrs:{"placeholder":((_vm.$t('login.company')) + " *")},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
        _vm.executeRecaptcha}},model:{value:(_vm.registerFormChild.company),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "company", $$v)},expression:"registerFormChild.company"}})],1):_vm._e(),(_vm.companySwitchChild)?_c('el-form-item',{attrs:{"prop":"companyUID"}},[_c('el-input',{attrs:{"placeholder":((_vm.$t('login.companyuid')) + " *")},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
        _vm.executeRecaptcha}},model:{value:(_vm.registerFormChild.companyUID),callback:function ($$v) {_vm.$set(_vm.registerFormChild, "companyUID", $$v)},expression:"registerFormChild.companyUID"}})],1):_vm._e(),_c('el-form-item',[_c('el-button',{staticClass:"btn-default",attrs:{"type":"primary"},on:{"click":function($event){_vm.validateStep('registerForm', _vm.progressActiveIndexChild = 1);
        _vm.executeRecaptcha}}},[_vm._v(_vm._s(_vm.$t('login.continue')))]),_c('p',{staticClass:"fake-btn-default",on:{"click":function($event){return _vm.backStep(_vm.progressActiveIndexChild = -1)}}},[_vm._v(_vm._s(_vm.$t('login.back')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }