<template>
  <!-- address:data START -->
  <div class="address-data">
    <div class="description">
      <p v-html="`${$t('login.registeraddress')}`"></p>
    </div>

    <!-- form:register:form:road START -->
    <el-form-item prop="road">
      <el-input
        :placeholder="`${$t('login.road')} *`"
        v-model="registerFormChild.road"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-input>
    </el-form-item>
    <!-- form:register:form:road END -->

    <div class="is-half">
      <!-- form:register:form:houseNumber START -->
      <el-form-item prop="houseNumber">
        <el-input
          :placeholder="`${$t('login.housenumber')} *`"
          v-model="registerFormChild.houseNumber"
          @keyup.enter.native="
            validateStep('registerForm', progressActiveIndexChild = 1);
            executeRecaptcha
          "
        ></el-input>
      </el-form-item>
      <!-- form:register:form:houseNumber END -->

      <!-- form:register:form:staircaseNumber START -->
      <el-form-item prop="staircaseNumber">
        <el-input
          :placeholder="`${$t('login.staircasenumber')}`"
          v-model="registerFormChild.staircaseNumber"
          @keyup.enter.native="
            validateStep('registerForm', progressActiveIndexChild = 1);
            executeRecaptcha
          "
        ></el-input>
      </el-form-item>
      <!-- form:register:form:staircaseNumber END -->
    </div>

    <div class="is-half">
      <!-- form:register:form:floor START -->
      <el-form-item prop="floor">
        <el-input
          :placeholder="`${$t('login.floor')}`"
          v-model="registerFormChild.floor"
          @keyup.enter.native="
            validateStep('registerForm', progressActiveIndexChild = 1);
            executeRecaptcha
          "
        ></el-input>
      </el-form-item>
      <!-- form:register:form:floor END -->

      <!-- form:register:form:door START -->
      <el-form-item prop="door">
        <el-input
          :placeholder="`${$t('login.door')}`"
          v-model="registerFormChild.door"
          @keyup.enter.native="
            validateStep('registerForm', progressActiveIndexChild = 1);
            executeRecaptcha
          "
        ></el-input>
      </el-form-item>
      <!-- form:register:form:door END -->
    </div>

    <!-- form:register:form:postcode START -->
    <el-form-item prop="postcode">
      <el-input
        :placeholder="`${$t('login.postcode')} *`"
        v-model="registerFormChild.postcode"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-input>
    </el-form-item>
    <!-- form:register:form:postcode END -->

    <!-- form:register:form:place START -->
    <el-form-item prop="place">
      <el-input
        :placeholder="`${$t('login.place')} *`"
        v-model="registerFormChild.place"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-input>
    </el-form-item>
    <!-- form:register:form:place END -->

    <!-- form:register:form:country START -->
    <el-form-item prop="country">
      <el-select
        v-if="isCurentLangEn"
        v-model="registerFormChild.country"
        :placeholder="`${$t('login.country.select')} *`"
        @keyup.enter.native="
            validateStep('registerForm', progressActiveIndexChild = 1);
            executeRecaptcha
        "
      >
        <el-option
          v-for="item in country"
          :key="item.code"
          :label="item.name_en"
          :value="item.code">
        </el-option>
      </el-select>
      <el-select
        v-model="registerFormChild.country"
        :placeholder="`${$t('login.country.select')} *`"
        v-else
      >
        <el-option
          v-for="item in country"
          :key="item.code"
          :label="item.name_de"
          :value="item.code">
        </el-option>
      </el-select>
    </el-form-item>
    <!-- form:register:form:country END -->

    <!-- theme-switch START -->
    <el-form-item>
      <el-switch
        v-model="companySwitchChild"
        :inactive-text="$t('login.firma')"
        active-color="#353635"
        inactive-color="#A0A0A0"
      ></el-switch>
    </el-form-item>
    <!-- theme-switch END -->

    <!-- form:register:form:company START -->
    <el-form-item prop="company" v-if="companySwitchChild">
      <el-input
        :placeholder="`${$t('login.company')} *`"
        v-model="registerFormChild.company"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-input>
    </el-form-item>
    <!-- form:register:form:company END -->

    <!-- form:register:form:companyUID START -->
    <el-form-item prop="companyUID" v-if="companySwitchChild">
      <el-input
        :placeholder="`${$t('login.companyuid')} *`"
        v-model="registerFormChild.companyUID"
        @keyup.enter.native="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      ></el-input>
    </el-form-item>
    <!-- form:register:form:companyUID END -->

    <!-- form:register:form:validate & calculate START -->
    <el-form-item>
      <el-button
        class="btn-default"
        type="primary"
        v-on:click="
          validateStep('registerForm', progressActiveIndexChild = 1);
          executeRecaptcha
        "
      >{{ $t('login.continue') }}</el-button>

      <p
        class="fake-btn-default"
        v-on:click="backStep(progressActiveIndexChild = -1)"
      >{{ $t('login.back') }}</p>
    </el-form-item>
    <!-- form:register:form:validate & calculate END -->
  </div>
  <!-- address:data END -->
</template>

<script>
export default {
  name: 'address-data',
  props: {
    registerForm: {
      type: Object,
    },

    validateStep: {
      type: Function,
    },

    executeRecaptcha: {
      type: Function,
    },

    backStep: {
      type: Function,
    },

    globalErrorMessage: {
      type: Boolean,
    },

    companySwitch: {
      type: Boolean,
    },

    progressActiveIndex: {
      type: Number,
    },
  },
  data() {
    return {
      registerFormChild: this.registerForm,
      companySwitchChild: this.companySwitch,
      progressActiveIndexChild: this.progressActiveIndex,
      country: null,
      isCurentLangEn: !!(localStorage && localStorage.getItem('lang') === 'en_US'),
    };
  },
  async created() {
    this.country = await this.FETCH_COUNTRIES();
  },
  methods: {
    async FETCH_COUNTRIES() {
      await this.$store.dispatch('setCountries');
      return this.$store.getters.countries;
    },
  },
  mounted() {
    this.validateStep();

    this.backStep();
  },
};
</script>
